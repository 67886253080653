import * as React from "react";
import PageLayout from "../components/PageLayout";
import styled, { keyframes, css } from "styled-components";
import Navbar from "../components/Navbar";
import SpaceHolder from "../components/Space";
import { useState, useRef, useEffect, useMemo } from "react";


// 'nails' section
import NLtechnique from "../assets/images/NLtechnique.png";
import NLdurability from "../assets/images/NLdurability.png";
import NLsafety from "../assets/images/NLsafety.png";
import NLhealth from "../assets/images/NLhealth.png";

// 'brows' section
import BRmethod from "../assets/images/BRmethod.png";
import BRshaping from "../assets/images/BRshaping.png";
import BRtint from "../assets/images/BRtint&shape.png";
import BRlami from "../assets/images/BRlami&shape.png";

// 'lashes' section
import LSHclassic from "../assets/images/LSHclassic.png";
import LSHhybrid from "../assets/images/LSHhybrid.png";
import LSHvolume from "../assets/images/LSHvolume.png";
import LSHlift from "../assets/images/LSHlift.png";

// 'location' section
import LCconcept from "../assets/images/LCconcept.png";
import LCossington from "../assets/images/LCossington.png";
import LCdupont from "../assets/images/LCdupont.png";


// Keyframes for sliding and fading animations
const slideOutLeft = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-400px); } //how far the 'project' is 
`;

const slideOutRight = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(400px); } //how far the 'essence.' is 
`;

const slideOutLeftMedium = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-250px); }
`;

const slideOutRightMedium = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(250px); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const highlightAnimation = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;


// welcome container styling
const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold; 
  width: 100%;
  height: 70vh;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.color2};

  @media (max-width: 767px) {
    height: auto; 
  }
`;

const WelcomeText = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 35px;
  margin-left: 10px;

  @media (max-width: 767px) {
    margin-top: 30px;
  }
`;

const ProjectEssenceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {  
    flex-direction: column;
    align-items: center;
    gap: 10px;  
  }
`;

const WelcomePart = styled.span`
  display: inline-block;
  white-space: nowrap;
  animation: ${({ animate, left }) => animate ? (left ? slideOutLeft : slideOutRight) : 'none'} 1s ease-out forwards;
  width: 50%;
  text-align: ${({ left }) => left ? 'right' : 'left'};
  position: relative;
  z-index: 2;

  @media (max-width: 1024px) and (min-width: 767px) {
    width: auto;
    animation: ${({ animate, left }) => animate ? (left ? slideOutLeftMedium : slideOutRightMedium) : 'none'} 1s ease-out forwards;
  }

   @media (max-width: 767px) {  
    width: auto;  
    animation: none;
    margin-right: ${({ left }) => left ? '13px' : '0'}; 
    margin-bottom: ${({ left }) => left ? '0' : '40px'}; 
  }
`;


// navigation styles for the welcome container
const Navigation = styled.div`
  display: flex;
  justify-content: center;
  max-width: 600px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: ${({ show }) => show ? fadeIn : 'none'} 1s ease-in-out forwards;
  visibility: ${({ show }) => show ? 'visible' : 'hidden'};
  z-index: 1;

  @media (max-width: 767px) {
    opacity: 1; 
    visibility: visible; 
    animation: none; 
    flex-direction: column; 
    position: static;
    transform: none;
    max-width: 100%; 
  }
`;

const NavigationItem = styled.span`
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 9px; 
  background-color: ${({ active }) => active ? '#EFF2EB' : 'transparent'}; 
  color: ${({ active }) => active ? '#000' : '#313328'}; 
  font-weight: ${({ active }) => active ? 'bold' : 'normal'}; 
  transition: background-color 0.3s ease, color 0.3s ease;

  
  ${({ active }) => !active && css`
    &:not(:hover) {
      animation: ${highlightAnimation} 10s infinite ease-in-out;
    }
  `}

  /* Sequential delay for each item */
  &:nth-child(1):not(:hover) {
    animation-delay: 0s;
  }
  &:nth-child(2):not(:hover) {
    animation-delay: 0.5s;
  }
  &:nth-child(3):not(:hover) {
    animation-delay: 1s;
  }
  &:nth-child(4):not(:hover) {
    animation-delay: 1.5s;
  }
  &:nth-child(5):not(:hover) {
    animation-delay: 2s;
  }

  &:hover {
    background-color: #EFF2EB; 
    border-radius: 9px;       
    padding: 10px 20px;
    animation: none;          
  }

  @media (max-width: 1024px) and (min-width: 767px) {
    padding: 8px 12px;
    margin: 0 4px;
    font-size: 24px;
  }
  
  @media (max-width: 767px) {
      animation: none; 
  }
`;

// common styles for Wrapper, NavTextContainer, etc.
const Wrapper = styled.div`
  max-width: 1400px;
  width: 95%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: flex-start;
  margin-top: 20px;

  @media (max-width: 1024px) and (min-width: 767px) {
    flex-direction: column;
    margin-left: 35px;
    margin-right: 35px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const NavTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) and (min-width: 767px) {
    flex: 1;
  }
`;

const NavColumn = styled.div`
  display: flex;
  font-family: 'SatoshiBlack', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', sans-serif;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  border-bottom: 4px solid #FFFFFF;

  button {
    background: none;
    border: none;
    color: #313328;
    font-size: 16px;
    cursor: pointer;
    padding: 20px 10px;
    font-weight: bold;
    text-transform: lowercase;
  }
`;

const ActiveMarker = styled.div`
  height: 4px;
  background-color: #313328;
  position: absolute;
  bottom: -2px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  transition: left 0.3s ease, width 0.3s ease;
`;

const ContentColumn = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  margin-left: 70px;
  margin-right: 20px;

  @media (max-width: 1024px) and (min-width: 767px) {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const TextColumn = styled.div`
  flex: 1;
  margin-left: 10px;

  h2 {
    font-size: 35px;
    color: #56594e;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 20px;
  }
`;

const CursiveParagraph = styled.p`
  font-style: italic; 
  margin-top: 20px; 
  color: #56594e; 
`;

//text after
const CenteredText = styled.p`
  text-align: center;
  color: #313328;  
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};  

  @media (max-width: 767px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 20px;
`;

const IndexPage = () => {
  const [activeContentNails, setActiveContentNails] = useState("technique");
  const [activeContentBrows, setActiveContentBrows] = useState("method");
  const [activeContentLashes, setActiveContentLashes] = useState("classic");
  const [activeContentLocation, setActiveContentLocation] = useState("concept");
  const [showNav, setShowNav] = useState(false);
  const [animateWelcome, setAnimateWelcome] = useState(false);
  const [visibleWrapper, setVisibleWrapper] = useState(null);

  const [markerStyleNails, setMarkerStyleNails] = useState({ left: 0, width: 0 });
  const [markerStyleBrows, setMarkerStyleBrows] = useState({ left: 0, width: 0 });
  const [markerStyleLashes, setMarkerStyleLashes] = useState({ left: 0, width: 0 });
  const [markerStyleLocation, setMarkerStyleLocation] = useState({ left: 0, width: 0 });

  const navRefsNails = useRef([]);
  const navRefsBrows = useRef([]);
  const navRefsLashes = useRef([]);
  const navRefsLocation = useRef([]);

  const navItemsNails = useMemo(() => ["technique", "durability", "safety", "health"], []);
  const navItemsBrows = useMemo(() => ["method", "shaping", "tint", "lamination"], []);
  const navItemsLashes = useMemo(() => ["classic", "hybrid", "volume", "lift"], []);
  const navItemsLocation = useMemo(() => ["concept", "ossington", "dupont"], []);

  const [activeNavItem, setActiveNavItem] = useState(null); // tracking active navigation item in welcome container


  // content for each section
  const contentsNails = {
    technique: { 
      title: "expert precision with 'russian mani/pedi'", 
      text: "all our nail services are performed using the meticulous eastern european technique, also known as the 'russian manicure.' this completely dry technique avoids the use of harsh acetone or water soaks and focuses on the health and longevity of your nails.",
      text2: "unlike traditional manicures and pedicures, our method employs a specialized e-file machine in combination with cuticle scissors or clippers to gently remove old polish, excess skin, and cuticles.",
      paragraph: "project essence’s nail artists are not only highly experienced but have also undergone rigorous professional training, making them true masters of their craft.", 
      image: NLtechnique },
    durability: { 
      title: "3-4 weeks of flawless, long-lasting results", 
      text: "our method ensures a clean, sophisticated result, with polish applied precisely to the edge of the nail bed and even underneath the well-prepared cuticle.",
      text2: "you can always count on our technicians' expertise to deliver the manicure of your dreams that lasts, as our talented nail artists excel in achieving a level of perfection that is truly rare.", 
      paragraph: "with proper aftercare, our manicures remain flawless for 3–4 weeks, free from chipping, breakage, or damage to your natural nails.", 
      image: NLdurability },
    safety: { 
      title: "perfect nails, prioritizing your safety", 
      text: "our ethos is to ensure that your manicure and pedicure are executed impeccably, with safety as our top priority. to learn more about our disinfection and sanitization protocols, visit our faq page or ask one of our team members during your next visit.", 
      paragraph: "quick note: we disinfect everything that comes into contact with a human hand in our salon and use single-use instruments (such as nail files and buffers) whenever possible.", 
      image: NLsafety },
    health: { 
      title: "consistentcy is the key", 
      text: "your nails are an important part of your body, and healthy nails are more than just a beauty statement. we highly advise maintaining consistency with your nail appointments (ideally every 3–4 weeks), even if you do not wear nail polish.",
      text2: "proper care helps prevent infections, such as fungal or bacterial issues, and keeps your nails from becoming brittle, breaking, or developing painful conditions like hangnails or ingrown nails.", 
      paragraph: "plus, well-maintained nails will significantly boost your self-esteem - and who doesn't like that?", 
      image: NLhealth },
  };

  const contentsBrows = {
    method: { 
      title: "yes, both brows can be your favourite.", 
      text: "at project essence, we believe that well-groomed eyebrows not only leave a lasting impression but also boost confidence. our focus is on enhancing your natural beauty by shaping brows that truly complement your unique look.",
      text2: "it is more than just removing unwanted hairs – we are all about designing the perfect brow structure and selecting a tint that complements your hair and skin tone. our goal is to create a harmonious, natural look that flatters your facial features.", 
      paragraph: "forget harsh lines or outdated trends – we are dedicated to creating effortless, polished brows tailored just for you.", 
      image: BRmethod },
    shaping: { 
      title: "different techniques, one perfect look", 
      text: "our brow artists are trained and experienced in a variety of techniques, from waxing and tweezing to threading. they often combine different methods to meet your specific needs, ensuring the best results.", 
      paragraph: "if you have a preferred technique, feel free to let us know, and we will gladly accommodate your request.",
      image: BRshaping },
    tint: { 
      title: "natural is our superpower", 
      text: "at project essence, we understand that beautifully tinted brows can transform your entire face.",
      text2: "we expertly balance the color of your brows with your overall style and hair tone, using premium professional products from europe.",
      paragraph: "it is never just about making them darker or lighter — we always take the time to craft the perfect colour match for a seamless, natural result.", 
      image: BRtint },
    lamination: { 
      title: "long-lasting perfection", 
      text: "brow lamination is essentially a 'brow hair texturizing treatment' that gives the appearance of fuller, more defined, and neatly groomed brows.",
      text2: "this non-invasive treatment involves setting the brow hairs into the desired shape using a safe chemical solution. the treatment typically lasts 4–6 weeks.", 
      paragraph: "for the best results, it can be combined with other services, such as 'shaping + lamination,' 'tint + lamination,' or the full package: 'shaping & tint + lamination.'", 
      image: BRlami },
  };

  const contentsLashes = {
    classic: { 
      title: "ideal for lovers of an exceptionally effortless look-lovers and lash extension first-timers", 
      text: "this technique provides your lashes with length and subtle fullness without being too bold — perfect for everyday wear. pure elegance.", 
      text2: "if you are uncertain if this is the right set for you, feel free to book it and bring reference photos to show our artist, who can help you choose the best option.", 
      paragraph: "our goal is to ensure that you leave our space with stunning lashes that perfectly suit your style and preferences.", 
      image: LSHclassic },
    hybrid: { 
      title: "our guests’ top choice for lash extensions", 
      text: "this technique blends ‘classic’ and ‘volume’ methods to achieve a textured, fuller look while maintaining a natural feel.",
      paragraph: "bye bye mascara!",
      text3: "unsure if hybrid is right for you? book the service, bring some inspiration photos, and our lash tech will help you find the perfect set.",
      paragraph2: "we promise that you will leave with lashes that enhance your natural beauty and complement your unique style.",
      image: LSHhybrid },
    volume: { 
      title: "perfect for anyone seeking a bold and dramatic effect", 
      text: "this set delivers a fluffy, voluminous look that's sure to make a statement.",
      text2: "if you are undecided that this is the right choice, feel free to bring reference pictures to your appointment.",
      paragraph: "our lash artist will help you choose the best set for your desired look and ensure you leave with lashes that truly shine.", 
      image: LSHvolume },
    lift: { 
      title: "wow your natural lashes, just lifted.", 
      text: "lash lift, also known as lash lamination, is a semi-permanent treatment that curls and lifts your natural lashes (it can be performed on bottom lashes as well), giving them a longer, more defined appearance for a month to a month and a half.",
      text2: "this is an ideal treatment for those who want a low-maintenance option with a natural look.", 
      paragraph: "we recommend adding a lash tint to enhance the effect by darkening your lashes for an even more striking appearance.",
      image: LSHlift },
  };

  const contentsLocation = {
    concept: { 
      title: "reconnect with your calm and essence", 
      text: "so many of us often feel burnt out due to high-stress and fast-paced living...",
      paragraph: "that's where we come to the rescue.",
      text2: "project essence is here to provide you with a stolen moment of peace during a busy week. each location is designed to be a representation of the natural elements, connecting you to the calming forces of earth and air.",
      text3: " our spaces offer a serene escape, allowing you to recharge, refresh, and restore balance in both mind and body. as a high-quality beauty space like no other, project essence has been committed to delivering exceptional services and creating an unparalleled experience for every guest.",
      image: LCconcept },
    ossington: { 
      title: "proudly opened in may 2023 on the world's 14th coolest street, as ranked by time out.", 
      text: "our ossington space is heavily influenced by the japandi aesthetic, a blend of japanese and scandinavian design concepts. we focus on the principles of simplicity, connection with the earth, and elevated comfort.",
      text2: "as a result, our space has been curated to be both aesthetically pleasing and functional.",
      text3: "our team strives to create an urban oasis that relaxes and inspires our guests to embrace mindfulness and well-being, all while having their nails, brows, and lashes look exquisite as they leave our boutique beauty lounge.",
      image: LCossington },
    dupont: { 
      title: "opens in november.",
      image: LCdupont },
  };

  
  // welcome animation
  useEffect(() => {
    setTimeout(() => {
      setAnimateWelcome(true);
      setTimeout(() => setShowNav(true), 1000);
    }, 1000);

    if (typeof window !== 'undefined' && window.innerWidth <= 767) {
      setShowNav(true);  // no animation mobile
    }

    // active for each wrapper
    if (visibleWrapper === "nailsWrapper") {
      const activeIndexNails = navItemsNails.indexOf(activeContentNails); // activeContentNails is "technique" by default
      const activeElementNails = navRefsNails.current[activeIndexNails];

      if (activeElementNails) {
        setMarkerStyleNails({
          left: activeElementNails.offsetLeft,
          width: activeElementNails.offsetWidth,
        });
      }
    }

    if (visibleWrapper === "browsWrapper") {
      const activeIndexBrows = navItemsBrows.indexOf(activeContentBrows); // activeContentBrows is "method" by default
      const activeElementBrows = navRefsBrows.current[activeIndexBrows];

      if (activeElementBrows) {
        setMarkerStyleBrows({
          left: activeElementBrows.offsetLeft,
          width: activeElementBrows.offsetWidth,
        });
      }
    }

    if (visibleWrapper === "lashesWrapper") {
      const activeIndexLashes = navItemsLashes.indexOf(activeContentLashes); // activeContentBrows is "classic" by default
      const activeElementLashes = navRefsLashes.current[activeIndexLashes];

      if (activeElementLashes) {
        setMarkerStyleLashes({
          left: activeElementLashes.offsetLeft,
          width: activeElementLashes.offsetWidth,
        });
      }
    }

    const activeIndexLocation = navItemsLocation.indexOf(activeContentLocation); // activeContentBrows is "concept" by default
    const activeElementLocation = navRefsLocation.current[activeIndexLocation];
    if (activeElementLocation) {
      setMarkerStyleLocation({
        left: activeElementLocation.offsetLeft,
        width: activeElementLocation.offsetWidth,
      });
    }
  }, [
    visibleWrapper,
    activeContentNails, navItemsNails,
    activeContentBrows, navItemsBrows,
    activeContentLashes, navItemsLashes,
    activeContentLocation, navItemsLocation,
  ]);

  return (
    <>
      <PageLayout>
        <Navbar pageAt="philosophy" />
        <SpaceHolder height={60} />

        {/* welcome animation */}
        <WelcomeContainer>
          <WelcomeText>welcome to</WelcomeText>
          <ProjectEssenceContainer>
            <WelcomePart animate={animateWelcome} left={true}>&nbsp;project</WelcomePart>
            {showNav && (
              <Navigation show={showNav}>
                {["nails", "brows", "lashes", "hair", "location"].map(item => (
                  <NavigationItem
                    key={item}
                    onClick={() => {
                      setVisibleWrapper(`${item}Wrapper`);
                      setActiveNavItem(item); // track active item
                    }}
                    active={activeNavItem === item} // active prop for styling
                  >
                    {item}
                  </NavigationItem>
                ))}
              </Navigation>
            )}
            <WelcomePart animate={animateWelcome} left={false}>&nbsp;essence.</WelcomePart>
          </ProjectEssenceContainer>
        </WelcomeContainer>

        <CenteredText visible={!visibleWrapper}>
          click on any of the categories you are interested in to see more details.
        </CenteredText>

        {/* nails wrapper */}
        {visibleWrapper === "nailsWrapper" && (
          <Wrapper showWrapper={true}>
            <NavTextContainer>
              <NavColumn>
                {navItemsNails.map((key, index) => (
                  <button
                    key={key}
                    ref={el => navRefsNails.current[index] = el}
                    onClick={() => setActiveContentNails(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
                ))}
                <ActiveMarker left={markerStyleNails.left} width={markerStyleNails.width} />
              </NavColumn>
              <TextColumn>
                <h2>{contentsNails[activeContentNails].title}</h2>
                <p>{contentsNails[activeContentNails].text}</p>
                <p>{contentsNails[activeContentNails].text2}</p>
                <CursiveParagraph>{contentsNails[activeContentNails].paragraph}</CursiveParagraph>
              </TextColumn>
            </NavTextContainer>
            <ContentColumn>
              <Image src={contentsNails[activeContentNails].image} alt={contentsNails[activeContentNails].title2} />
            </ContentColumn>
          </Wrapper>
        )}

        {/* brows wrapper */}
        {visibleWrapper === "browsWrapper" && (
          <Wrapper showWrapper={true}>
            <NavTextContainer>
              <NavColumn>
                {navItemsBrows.map((key, index) => (
                  <button
                    key={key}
                    ref={el => navRefsBrows.current[index] = el}
                    onClick={() => setActiveContentBrows(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
                ))}
                <ActiveMarker left={markerStyleBrows.left} width={markerStyleBrows.width} />
              </NavColumn>
              <TextColumn>
                <h2>{contentsBrows[activeContentBrows].title}</h2>
                <p>{contentsBrows[activeContentBrows].text}</p>
                <p>{contentsBrows[activeContentBrows].text2}</p>
                <CursiveParagraph>{contentsBrows[activeContentBrows].paragraph}</CursiveParagraph>
              </TextColumn>
            </NavTextContainer>
            <ContentColumn>
              <Image src={contentsBrows[activeContentBrows].image} alt={contentsBrows[activeContentBrows].title2} />
            </ContentColumn>
          </Wrapper>
        )}

        {/* lashes wrapper */}
        {visibleWrapper === "lashesWrapper" && (
          <Wrapper showWrapper={true}>
            <NavTextContainer>
              <NavColumn>
                {navItemsLashes.map((key, index) => (
                  <button
                    key={key}
                    ref={el => navRefsLashes.current[index] = el}
                    onClick={() => setActiveContentLashes(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
                ))}
                <ActiveMarker left={markerStyleLashes.left} width={markerStyleLashes.width} />
              </NavColumn>
              <TextColumn>
                <h2>{contentsLashes[activeContentLashes].title}</h2>
                <p>{contentsLashes[activeContentLashes].text}</p>
                <CursiveParagraph>{contentsLashes[activeContentLashes].paragraph}</CursiveParagraph>
                <p>{contentsLashes[activeContentLashes].text3}</p>
                <CursiveParagraph>{contentsLashes[activeContentLashes].paragraph2}</CursiveParagraph>
              </TextColumn>
            </NavTextContainer>
            <ContentColumn>
              <Image src={contentsLashes[activeContentLashes].image} alt={contentsLashes[activeContentLashes].title2} />
            </ContentColumn>
          </Wrapper>
        )}

        {/* hair wrapper */}
        {visibleWrapper === "hairWrapper" && (
          <Wrapper showWrapper={true} style={{ justifyContent: "center", alignItems: "center" }}>
            <TextColumn style={{ textAlign: "center" }}>
              <h2>we are expanding into hair services, stay tuned.</h2>
            </TextColumn>
          </Wrapper>
        )}

        {/* location wrapper */}
        {visibleWrapper === "locationWrapper" && (
          <Wrapper showWrapper={true}>
            <NavTextContainer>
              <NavColumn>
                {navItemsLocation.map((key, index) => (
                  <button
                    key={key}
                    ref={el => navRefsLocation.current[index] = el}
                    onClick={() => setActiveContentLocation(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
                ))}
                <ActiveMarker left={markerStyleLocation.left} width={markerStyleLocation.width} />
              </NavColumn>
              <TextColumn>
                <h2>{contentsLocation[activeContentLocation].title}</h2>
                <p>{contentsLocation[activeContentLocation].text}</p>
                <CursiveParagraph>{contentsLocation[activeContentLocation].paragraph}</CursiveParagraph>
                <p>{contentsLocation[activeContentLocation].text2}</p>
                <p>{contentsLocation[activeContentLocation].text3}</p>
              </TextColumn>
            </NavTextContainer>
            <ContentColumn>
              <Image src={contentsLocation[activeContentLocation].image} alt={contentsLocation[activeContentLocation].title2} />
            </ContentColumn>
          </Wrapper>
        )}
      </PageLayout>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Project Essence</title>;
