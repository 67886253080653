import React from "react";
import styled from "styled-components";

const Space = styled.div`
  width: ${(props) => (props.width != null ? `${props.width}px` : `1px`)};
  height: ${(props) => (props.height != null ? `${props.height}px` : `1px`)};
`;

const SpaceHolder = (props) => {
  return <Space height={props.height} width={props.width} />;
};

export default SpaceHolder;
